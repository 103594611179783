import { createSlice } from '@reduxjs/toolkit'
import { areObjectsEqual } from 'utils/utils'

const initialState = []

const localAnswers = createSlice({
	name: 'localAnswers',
	initialState,
	reducers: {
		addLocalAnswer: (state, { payload }) => [...state, payload],
		removeLocalAnswer: (state, { payload }) =>
			state.filter(data => !areObjectsEqual(data, payload)),
		clearLocalAnswers: (_, __) => initialState
	}
})

export const { addLocalAnswer, removeLocalAnswer, clearLocalAnswers } =
	localAnswers.actions
export default localAnswers.reducer
