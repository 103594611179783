import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// Import your reducers here
import userReducer from './slices/user'
import dataReducer from './slices/data'
import authReducer from './slices/auth'
import passReducer from './slices/pass'
import localAnswersReducer from './slices/localAnswers'
import localDataReducer from './slices/localData'
import testReducer from './slices/test'

// Set up the persist configuration
const persistConfig = {
	key: 'root',
	storage
}

// Combine your reducers into a single root reducer
const rootReducer = combineReducers({
	user: userReducer,
	data: dataReducer,
	auth: authReducer,
	pass: passReducer,
	localAnswers: localAnswersReducer,
	localData: localDataReducer,
	test: testReducer
})

// Create the persisted reducer using the persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Create the Redux store with the persisted reducer
const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		})
})

// Create the persisted store
const persistor = persistStore(store)

export { store, persistor }
