import ReactDOM from 'react-dom/client'
import App from './App'
import 'assets/styles/index.scss'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-toastify/dist/ReactToastify.css'
import { Provider } from 'react-redux'
import { store, persistor } from 'store/store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<ErrorBoundary>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</ErrorBoundary>
)

serviceWorkerRegistration.register({
	onUpdate: registration => {
		const waitingServiceWorker = registration.waiting
		if (waitingServiceWorker) {
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
			waitingServiceWorker.addEventListener('statechange', event => {
				if (event.target.state === 'activated') {
					window.location.reload()
				}
			})
		}
	}
})
