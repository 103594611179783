export const getRomanNumeral = index => {
	switch (index) {
		case 0:
			return 'A'
		case 1:
			return 'B'
		case 2:
			return 'C'
		case 3:
			return 'D'
		default:
			return 'A'
	}
}

export const calculateDeviceRatio = (width, height) => width >= height * 1.7

export const delayDurations = {
	pageTransitions: 700,
	answerDelays: 2000
}

export const replaceHttpWithHttps = obj => {
	// Iterate over each property of the object
	for (let prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			// Check if the property is an object itself
			if (typeof obj[prop] === 'object') {
				// If it is an object, recursively call the function
				obj[prop] = replaceHttpWithHttps(obj[prop])
			} else if (
				typeof obj[prop] === 'string' &&
				obj[prop].startsWith('http:')
			) {
				// If it is a string starting with 'http:', replace it with 'https:'
				obj[prop] = obj[prop].replace(/^http:/, 'https:')
			}
		}
	}

	return obj
}

export const areObjectsEqual = (obj1, obj2) => {
	const keys1 = Object.keys(obj1)
	const keys2 = Object.keys(obj2)
	if (keys1.length !== keys2.length) {
		return false
	}
	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false
		}
	}
	return true
}

export const testBody = {
	language_id: '1',
	event_id: 4,
	enter_name: 'test 1',
	at_minimum_how_cold_should_a_freezer_be_for_storing_food: '-18°C',
	you_want_to_start_a_garden_what_is_the_most_important_element_to_grow_strong_and_healthy_plants:
		'A barrel to collect \nrain water',
	in_agriculture_what_are_the_three_sisters: 'None of the above',
	how_many_eggs_does_a_hen_lay_per_day: '4',
	true_or_false_aquaculture_or_aquafarming_is_growing_crops_in_water_like_rice:
		'False',
	in_which_canadian_city_was_the_first_rooftop_commercial_greenhouse_built:
		'Yellowknife',
	a_food_system_includes_everything_involved_in_making_food_and_getting_it_to_your_table_which_of_these_elements_is_not_part_of_a_food_system:
		'Food processing facility',
	as_a_local_food_source_indigenous_peoples_gather_wild_plants_for_eating_this_is_called:
		'All of the above',
	what_is_one_action_a_farmer_can_take_to_keep_their_soil_healthy_and_maintain_organic_matter_within_it:
		'Keep the soil dry',
	what_is_the_sign_of_good_and_healthy_soil: 'All of the above'
}
