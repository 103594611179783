import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const data = createSlice({
	name: 'data',
	initialState,
	reducers: {
		setData: (_, { payload }) => payload,
		clearData: (_, __) => initialState
	}
})

export const { setData, clearData } = data.actions
export default data.reducer
