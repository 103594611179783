import { createSlice } from '@reduxjs/toolkit'

const initialState = false

const test = createSlice({
	name: 'test',
	initialState,
	reducers: {
		setTestMode: (state, { payload }) => payload
	}
})

export const { setTestMode } = test.actions
export default test.reducer
