import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

const pass = createSlice({
	name: 'pass',
	initialState,
	reducers: {
		setPass: (_, { payload }) => payload
	}
})

export const { setPass } = pass.actions
export default pass.reducer
