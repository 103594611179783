import { createSlice } from '@reduxjs/toolkit'

const initialState = false

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authorize: (_, __) => true
	}
})

export const { authorize } = auth.actions
export default auth.reducer
