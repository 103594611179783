import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Loading from 'components/Loading/Loading'
import Layout from 'components/Layout/Layout'
import { useSelector } from 'react-redux'

const Home = lazy(() => import('pages/Home/Home'))
const Questions = lazy(() => import('pages/Questions/Questions'))
const Congrats = lazy(() => import('pages/Congrats/Congrats'))
const Leaderboard = lazy(() => import('pages/Leaderboard/Leaderboard'))
const NotFound = lazy(() => import('pages/NotFound/NotFound'))
const Login = lazy(() => import('pages/Login/Login'))

const App = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const auth = useSelector(state => state.auth)
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const eventParam = searchParams.get('event')
		if (eventParam) return
		if (!auth && !location.pathname.includes('leaderboard')) navigate('/login')
	}, [auth])
	return (
		<Layout>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/login' element={<Login />} />
					<Route path='/questions' element={<Questions />} />
					<Route path='/congrats' element={<Congrats />} />
					<Route path='/leaderboard' element={<Leaderboard />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</Suspense>
		</Layout>
	)
}

export default App
