import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const localData = createSlice({
	name: 'localData',
	initialState,
	reducers: {
		setLocalData: (_, { payload }) => payload,
		clearLocalData: (_, __) => initialState
	}
})

export const { setLocalData, clearLocalData } = localData.actions
export default localData.reducer
