import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUserLanguage: (state, { payload }) => ({
			...state,
			language: payload
		}),
		updateUserScore: (state, { payload }) => ({ ...state, score: payload }),
		updateUserToken: (state, { payload }) => ({ ...state, token: payload }),
		updateUserEvent: (state, { payload }) => ({ ...state, event_id: payload }),
		updateUserLocalScore: (state, { payload }) => ({
			...state,
			localScore: payload
		}),
		clearUserScore: (state, _) => ({ ...state, score: null }),
		clearUserLocalScore: (state, _) => ({ ...state, localScore: null }),
		clearUser: (_, __) => initialState
	}
})

export const {
	updateUserLanguage,
	updateUserScore,
	updateUserToken,
	updateUserEvent,
	updateUserLocalScore,
	clearUserScore,
	clearUserLocalScore,
	clearUser
} = user.actions
export default user.reducer
