import './Loading.scss'

const Loading = () => {
	return (
		<div className='Loading'>
			<span className='loader'>Loading</span>
		</div>
	)
}

export default Loading
